import React, { useState } from 'react';
import './style.css';
import YouTube from 'react-youtube';
import Stepper from "awesome-react-stepper";
import Swal from 'sweetalert2';
import { downloadWaypointImage, unlockWaypoint } from '../../globalService/api.service';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const Modal = ({ isOpen, onClose, getUser, waypoint, status }) => {
  const [activeOption, setActiveOption] = useState(null);
  const [emailSent, setEmailSent] = useState(false); // State to track if email is sent

  const params = useParams();
  const { userId } = params;

  if (!isOpen) return null;

  const handleOptionSelect = (key) => {
    setActiveOption(key);
  };

  const onSubmitWaypoint = async () => {
    if (isOpen === "is_unlocked") {
      setActiveOption(null);
      onClose();
    } else if (waypoint.quiz) {
      const answer = activeOption;
      const title = activeOption ? 'Do you want to submit your answer?' : 'No answer chosen. Do you want to skip?';

      Swal.fire({
        title,
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Submit',
        denyButtonText: `Don't save`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { _id } = waypoint;
          try {
            let response = null;
            if (activeOption && isOpen !== "is_unlocked") response = await unlockWaypoint(_id, userId, { answer });
            if (response?.is_success) {
              getUser();
              const primaryMsg = "Waypoint Unlocked";
              const secondaryMsg = response.is_correct ? `Amazing! Well Done! | Reward : ${response.reward}` : `Never Mind, Keep Going! | Correct Answer: ${waypoint.quiz.options[response.answer]}`;
              Swal.fire(primaryMsg, secondaryMsg, "success");
            } else {
              Swal.fire("Something went wrong", "Try again later", "error");
            }
            setActiveOption(null);
            onClose();
          } catch (error) {
            console.log("err", error);
            Swal.fire("Something went wrong", "Try again later", "error");
            setActiveOption(null);
            onClose();
          }
        }
      });
    } else {
      const { _id } = waypoint;
      await unlockWaypoint(_id, userId, {});
      getUser();
      setActiveOption(null);
      onClose();
      Swal.fire("Waypoint unlocked", "Go ahead", "success");
    }
  };

  const handleDownload = async (waypointId) => {
    await downloadWaypointImage(waypointId, userId);
    setEmailSent(true); // Set the state to true when email is sent
  };

  const generateContent = () => {
    let { video, image, text, quiz, redirectUrl } = waypoint;
    
    if (video) video = <YouTube id="yt" className='content-container' videoId={video} />;
    if (image) image = (
      <div>
        <img width={310} className='content-container' style={{cursor: "pointer"}} src={image} onClick={() => {
              // window.location.href = redirectUrl;
              redirectUrl && window.open(redirectUrl, '_blank');

        }} />
        {waypoint.is_img_dwnld ? (
          <button onClick={() => handleDownload(waypoint._id)}
          style={{
              backgroundColor: emailSent ? "#c17846" : "#f0f0f0",
              borderRadius: "10px",
              border: "none",
              padding: "10px",
              color: "#000",
              cursor: "pointer",
            }}
            >
            {emailSent ? "EMAIL SENT" : "SEND BY EMAIL"}
          </button>
        ) : null}
      </div>
    );
    if (text) text = <p className='content-container'>{text}</p>;

    if (quiz) {
      const { question, answer, options } = quiz;
      if (question && options) {
        quiz = (
          <div className='content-container'>
            <h3>{isOpen === "is_unlocked" ? "🎉 Waypoint is unlocked!" : "Now here is something for you:"}</h3>
            <div className='quiz-card'>
              <h4 style={{ color: "#c17846" }}>{question}</h4>
              {isOpen === "is_unlocked" ? (
                <div style={{ fontWeight: "bold" }}>{`Correct Answer : ${options[answer]}`}</div>
              ) : (
                Object.keys(options).map((key) => (
                  <div
                    key={key}
                    className={`quiz-option ${activeOption == key ? "active-quiz-option" : "inactive-quiz-option"}`}
                    onClick={() => handleOptionSelect(key)}
                  >
                    {options[key]}
                  </div>
                ))
              )}
            </div>
          </div>
        );
      }
    }

    const orderObject = { video, image, text, quiz };
    const order = waypoint?.order?.length ? waypoint.order : [];
    return order.map(o => orderObject[o]).filter(o => o && o);
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="cross-button close-button" onClick={onClose}>
          <span className="horizontal-line"></span>
          <span className="vertical-line"></span>
        </div>
        <h4>{waypoint?.title}</h4>

        <Stepper onSubmit={() => onSubmitWaypoint()} submitBtn={<button className='defaultBtn'>{isOpen === "is_unlocked" ? "CLOSE" : "COMPLETE"}</button>}>
          {waypoint ? generateContent() : <>loading...</>}
        </Stepper>
      </div>
    </div>
  );
};

export default Modal;
